<template>
  <div id="login-page">
    <div class="row">
      <div class="fourteen wide column">
        <img
          class="ui centered small image"
          :src="logo"
          alt="Logo de l'application"
        >
        <h2 class="ui center aligned icon header">
          <div class="content">
            {{ APPLICATION_NAME }}
            <div class="sub header">
              {{ APPLICATION_ABSTRACT }}
            </div>
          </div>
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="six wide column">
        <h3 class="ui horizontal divider header">
          CONNEXION
        </h3>

        <div
          v-if="form.errors"
          class="ui warning message"
        >
          <div class="header">
            Les informations d'identification sont incorrectes.
          </div>
          NB: Seuls les comptes actifs peuvent se connecter.
        </div>

        <form
          class="ui form"
          role="form"
          type="post"
          @submit.prevent="login"
        >
          <div class="ui stacked secondary segment">
            <div class="six field required">
              <div class="ui left icon input">
                <i
                  class="user icon"
                  aria-hidden="true"
                />
                <input
                  v-model="username_value"
                  type="text"
                  name="username"
                  placeholder="Utilisateur"
                >
              </div>
            </div>
            <div class="six field required">
              <div class="ui left icon input">
                <i
                  class="lock icon"
                  aria-hidden="true"
                />
                <input
                  v-model="password_value"
                  type="password"
                  name="password"
                  placeholder="Mot de passe"
                >
              </div>
            </div>
            <button
              class="ui fluid large teal submit button"
              type="submit"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      username_value: null,
      password_value: null,
      logged: false,
      form: {
        errors: null,
      },
    };
  },
  computed: {
    logo() {
      return this.$store.state.configuration.VUE_APP_LOGO_PATH;
    },
    APPLICATION_NAME() {
      return this.$store.state.configuration.VUE_APP_APPLICATION_NAME;
    },
    APPLICATION_ABSTRACT() {
      return this.$store.state.configuration.VUE_APP_APPLICATION_ABSTRACT;
    },
  },

  mounted() {
    if (this.$store.state.user) {
      this.$store.commit(
        'DISPLAY_MESSAGE',
        { comment: 'Vous êtes déjà connecté, vous allez être redirigé vers la page précédente.' }
      );
      setTimeout(() => this.$store.dispatch('REDIRECT_AFTER_LOGIN'), 3100);
    }
  },
  methods: {
    login() {
      this.$store
        .dispatch('LOGIN', {
          username: this.username_value,
          password: this.password_value,
        })
        .then((status) => {
          if (status === 200) {
            this.form.errors = null;
          } else if (status === 'error') {
            this.form.errors = status;
          }
        })
        .catch();
    },
  },
};
</script>

<style lang="less" scoped>

#login-page {
  max-width: 500px;
  min-width: 200px;
  margin: 3em auto;
}

</style>